/**
 * Created by AGUANG on 2017/2/20.
 */
export class ErrorCode {
  private static errorMsg: any = {
    'no_user': '没有该用户',
    'login_expired': '登录过期',
    'param_error': '参数错误',
    'server_error': '系统内部发生错误',
    'password_incorrect': '密码错误',
    'unknown': '未知错误',
    'cors': '登陆超时，请重新登录',
    'timeout': '网络请求超时，请重试',
    '0': '网络未连接，请检查网络设置',
    'user_exist': '用户已存在',
    'account_not_valid': '账户格式不正确(6-16位的英文、数字组成)',
    'password_not_valid': '密码格式不正确(6-16位的英文、数字组成)',
    'user_not_exist': '用户不存在',
    'operator_is_exist': '运营商已存在',
    'data_not_exist': 'Id不存在',
    '110001': '出错',
    '110002': '参数错误',
    '113301': '上传文件不存在',
    '113302': '上传文件格式错误',
    'can_not_unbind': '车队长不能被解绑',
    'token_not_exist': '凭证不存在，请刷新页面',
    'access_token_not_exist': 'accesstoken获取失败',
    'SERVER_BUSY': '系统繁忙',
    'error_tag': '非法的标签',
    'user_tag_out_size': '有粉丝身上的标签数已经超过限制，即超过20个',
    'error_openid': '传入非法的openid',
    'app_not_exist_openid': '传入的openid不属于此AppID',
    'captain_already_bind': '车队长已被绑定',
    'report_not_create': '报告尚未生成，请重新选择时间段。'

  };

  static getText(msg: string): string {

    return ErrorCode.errorMsg[msg] as string;

  }
}
